const getMountingComponents = (selectedOptions, tipoSuporte) => {
    const components = [];
    let fator_mp_tandem = 1;
    if (selectedOptions["Tandem"] === "Sim") {
        if (selectedOptions["Tipo de Bomba"] === "Hidráulico") {
            fator_mp_tandem = 2;

        }
        else {
            fator_mp_tandem = parseFloat(selectedOptions["Seções"]);
        }
    }
    if (tipoSuporte === "Padrão") {
        if (selectedOptions["Reservatório"] === "400") {
            components.push({ codigo: "9905103560005", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000035", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "600" || selectedOptions["Reservatório"] === "800") {
            components.push({ codigo: "9905103560007", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000035", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "1200") {
            components.push({ codigo: "9905103560017", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000035", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "2200") {
            components.push({ codigo: "9905103560018", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000035", quantity: 1*fator_mp_tandem });
        }
    } else if (tipoSuporte === "Momentum Lateral") {
        if (selectedOptions["Reservatório"] === "400") {
            components.push({ codigo: "FT9905104", quantity: 0.5*fator_mp_tandem });
            components.push({ codigo: "990838000040", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "600" || selectedOptions["Reservatório"] === "800") {
            components.push({ codigo: "FT9905104", quantity: 0.5*fator_mp_tandem });
            components.push({ codigo: "990838000040", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "1200") {
            components.push({ codigo: "FT9905104", quantity: 0.5*fator_mp_tandem });
            components.push({ codigo: "990838000040", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "2200") {
            components.push({ codigo: "FT9905104", quantity: 0.5*fator_mp_tandem });
            components.push({ codigo: "990838000040", quantity: 1*fator_mp_tandem });
        }
    }
    else if (tipoSuporte === "Momentum Traseiro") {
        if (selectedOptions["Reservatório"] === "400") {
            components.push({ codigo: "FT9905102", quantity: 1 });
            components.push({ codigo: "990838000041", quantity: 1 });

        }
        else if (selectedOptions["Reservatório"] === "600" || selectedOptions["Reservatório"] === "800") {
            components.push({ codigo: "FT9905102", quantity: 1 });
            components.push({ codigo: "990838000041", quantity: 1 });
        }
        else if (selectedOptions["Reservatório"] === "1200") {
            components.push({ codigo: "FT9905102", quantity: 1 });
            components.push({ codigo: "990838000041", quantity: 1 });
        }
        else if (selectedOptions["Reservatório"] === "2200") {
            components.push({ codigo: "FT9905102", quantity: 1 });
            components.push({ codigo: "990838000041", quantity: 1 });
        }
    }
    else if (tipoSuporte === "Absoluta") {
        if (selectedOptions["Reservatório"] === "400") {
            components.push({ codigo: "FT9905105", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000042", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "600" || selectedOptions["Reservatório"] === "800") {
            components.push({ codigo: "FT9905105", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000042", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "1200") {
            components.push({ codigo: "FT9905105", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000042", quantity: 1*fator_mp_tandem });
        }
        else if (selectedOptions["Reservatório"] === "2200") {
            components.push({ codigo: "FT9905105", quantity: 1*fator_mp_tandem });
            components.push({ codigo: "990838000042", quantity: 1*fator_mp_tandem });
        }
    }
    return components
}

const tankInterfaceConfigurationMap = (tankConfigurationMap) => {
    let configurations = {
        "Tipo Suporte": [
            { label: "Padrão", code: "SP", components: getMountingComponents(tankConfigurationMap, "Padrão") },
            { label: "Momentum Lateral", code: "ML", components: getMountingComponents(tankConfigurationMap, "Momentum Lateral") },
            { label: "Momentum Traseiro", code: "MT", components: getMountingComponents(tankConfigurationMap, "Momentum Traseiro") },
            //{ label: "Absoluta", code: "AB", components: getComponentsSuport(tankConfigurationMap, "Absoluta") },
        ],
    };
    if (tankConfigurationMap["Tipo de Bomba"] === "Hidráulico") {
        configurations = {
            ...configurations,
            "Mangueira Hidráulica": [
                {
                    label: "11m", code: "A6",
                    components: [{
                        codigo: "100855000016", quantity: 1
                    }
                    ]
                },
                {
                    label: "15m", code: "A8",
                    components: [{
                        codigo: "100855000010", quantity: 1
                    }]
                },
                {
                    label: "16m", code: "AC",
                    components: [{
                        codigo: "100855000014", quantity: 1
                    }]
                },
                {
                    label: "18m", code: "AC",
                    components: [{
                        codigo: "100855000015", quantity: 1
                    }]
                }
            ],
        }
        if (tankConfigurationMap["Tandem"] === "Sim") {
            configurations = {
                ...configurations,
                "Mangueira Entre Tanques": [
                    {
                        label: "6m", code: "A6",
                        components: [{
                            codigo: "100855000002", quantity: 6
                        }
                        ]
                    },
                    {
                        label: "8m", code: "A8",
                        components: [{
                            codigo: "100855000002", quantity: 8
                        }]
                    },
                    {
                        label: "12m", code: "AC",
                        components: [{
                            codigo: "100855000002", quantity: 12
                        }]
                    }
                ],
            }
        }
    }

    return configurations;
}

export default tankInterfaceConfigurationMap;