// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: '0.1rem', // Add the desired margin
                    marginBottom: '0.1rem', // Add the desired margin
                }
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: '1rem', // Add the desired margin
                },
            },
        }
    },
});

export default theme;
