const tankStructureConfigurationMap = () => {
    const configurations = {
        "Reservatório": [{ label: "400", code: "40" }, { label: "600", code: "60" }, { label: "800", code: "80" }, { label: "1200", code: "12" }, { label: "2200", code: "22" }],
        "Tipo de Bomba": [{ label: "Elétrico", code: "E" }, { label: "Hidráulico", code: "H" }],
        "Seções": [{ label: "1", code: "S1" }, { label: "2", code: "S2" }, { label: "3", code: "S3" }, { label: "4", code: "S4" }],
        "Tandem": [{ label: "Sim", code: "T" }, { label: "Não", code: "U" }],
        "Taxa": [{ label: "Sim", code: "C" }, { label: "Não", code: "F" }],
        "Abastecimento": [{ label: "Engate Rápido", code: "R" }, { label: "Não", code: "N" }],
        "Cor Chassi": [{ label: "Cinza", code: "cz" }, { label: "Preto", code: "pt" }, { label: "Vermelho", code: "vm" }],
        "Cor Tanque": [{ label: "Cinza", code: "cz" }, { label: "Amarelo", code: "am" }, { label: "Branco", code: "br" }],
        //"Controle": [{ label: "F-15", code: "f" }, { label: "ISOBUS", code: "w" }],
        //"Sensor de Início": [{ label: "Indutivo", code: "i" }, { label: "Semente", code: "s" }, { label: "Monitor de Plantio", code: "m" }],

    };
    return configurations;
}

export default tankStructureConfigurationMap;