import React, { useState } from 'react';
import { Container, Card, CardContent, Typography, TextField, Button, ThemeProvider } from '@mui/material';
import axios from './axiosConfig'; // Import the configured Axios instance
import { Link, useNavigate } from 'react-router-dom';
import theme from './theme'; // Import your custom theme

import { useAuth } from './AuthContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();
    const handleLogin = async () => {
        try {
            const response = await axios.post('/login', { email, password });
            if (response.status === 200) {
                // Successful login, handle redirection or other actions
                console.log(response.data);
                if (response.data['user'])
                    login(response.data['user'], response.data['token']);
            } else {
                setLoginError(true);
            }
        } catch (error) {
            setLoginError(true);
        }
    };

    const handleSignup = async () => {
        // redirect the user to /signup
        navigate('/signup');
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card sx={{
                    boxShadow: 4,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    p: 2,
                    m: 2,
                    borderRadius: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Login
                        </Typography>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            label="Senha"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <Button onClick={handleLogin} variant="contained" color="primary">
                                Entrar
                            </Button>
                            <Button onClick={handleSignup} variant="contained" color="primary">
                                Criar Conta
                            </Button>
                        </div>
                        {loginError && (
                            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                <Typography color="error">Falha de email ou senha, tente novamente.</Typography>
                                <Button component={Link} to="/reset-password" color="primary">
                                    Esqueceu a senha?
                                </Button>
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Container>


        </ThemeProvider>
    );
};

export default Login;
