
import React, { useState } from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { Container, Grid, Paper, TextField, Button } from '@mui/material';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
} from '@mui/material';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Add as AddIcon, Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import { ArrowUpward as ArrowUpIcon, ArrowDownward as ArrowDownIcon } from '@mui/icons-material';

import theme from './theme'; // Import your custom theme
import tankStructureConfigurationMap from './TankInterfaceConfigurationMap';
import { get } from 'react-hook-form';

const ConfigurationEditor = ({ configurations }) => {
    const [selectedEquipment, setSelectedEquipment] = useState(configurations['Tanque']);


    const handleEquipmentSelect = (equipment) => {
        setSelectedEquipment(configurations[equipment]);
    };

    const handleVariationChange = (variation, index, field, newValue) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            updatedConfigurations[variation][index][field] = newValue;
            return updatedConfigurations;
        });
    };

    const handleRemoveVariation = (variation) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            delete updatedConfigurations[variation];
            return updatedConfigurations;
        });
    };

    const handleMoveVariation = (fromIndex, toIndex) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            const variations = Object.keys(updatedConfigurations);
            const [movedVariation] = variations.splice(fromIndex, 1);
            variations.splice(toIndex, 0, movedVariation);
            return Object.fromEntries(
                variations.map((variation) => [variation, updatedConfigurations[variation]])
            );
        });
    };

    const handleRemoveOption = (variation, index) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            updatedConfigurations[variation].splice(index, 1);
            return updatedConfigurations;
        });
    };

    const handleMoveOption = (variation, fromIndex, toIndex) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            const options = updatedConfigurations[variation];
            const [movedOption] = options.splice(fromIndex, 1);
            options.splice(toIndex, 0, movedOption);
            return updatedConfigurations;
        });
    };

    const handleAddOption = (variation) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = { ...prevSelectedEquipment };
            updatedConfigurations[variation].push({ label: '', code: '' });
            return updatedConfigurations;
        });
    };

    const handleAddVariation = (newVariation) => {
        setSelectedEquipment((prevSelectedEquipment) => {
            const updatedConfigurations = {
                ...prevSelectedEquipment,
                [newVariation]: [],
            };
            return updatedConfigurations;
        });
    };

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [newVariationName, setNewVariationName] = useState('');

    const handleSave = () => {
        setConfirmAction("save");
        setIsConfirmationOpen(true);
    };

    const handleDismiss = () => {
        setConfirmAction("dismiss");
        setIsConfirmationOpen(true);
    };

    const handleConfirm = () => {
        setIsConfirmationOpen(false);
        if (confirmAction === "save") {
            // Implement your save logic here
        } else if (confirmAction === "dismiss") {
            // Implement your dismiss logic here
        } else if (confirmAction === "addVariation") {
            if (newVariationName) {
                handleAddVariation(newVariationName);
                setNewVariationName('');
            }
        }
    };

    const handleCancel = () => {
        setIsConfirmationOpen(false);
        setNewVariationName('');
    };


    const handleOpenAddVariationDialog = () => {
        setIsConfirmationOpen(true);
        setConfirmAction("addVariation");
    };

    const getSelectedEquipment = () => {

        if (selectedEquipment) {
            return Object.keys(configurations).find(
                (equipment) => configurations[equipment] === selectedEquipment
            );
        }
    };

    return (
        <Container maxWidth="lg">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Equipamento</InputLabel>

                        <Select
                            value={Object.keys(configurations).find(
                                (equipment) => configurations[equipment] === selectedEquipment
                            )}
                            onChange={(e) => { handleEquipmentSelect(e.target.value) }}
                            label="Equipamento"
                        >
                            {Object.keys(configurations).map((equipment) => (
                                <MenuItem key={equipment} value={equipment}>
                                    {equipment}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                    {selectedEquipment && (
                        <>
                            <div style={{ justifyContent: 'end', display: 'flex', paddingRight: '20px' }}>
                                <IconButton onClick={handleSave}>
                                    <SaveIcon />
                                </IconButton>
                                <IconButton onClick={handleDismiss}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Paper style={{ overflowY: 'scroll', maxHeight: '85vh' }}>

                                <Grid container spacing={2} style={{ padding: '1rem' }}>
                                    {Object.entries(selectedEquipment).map(
                                        ([variation, options], index) => (
                                            <Grid item key={variation} xs={12}>
                                                <div style={{ display: 'flex' }}><IconButton

                                                    onClick={() => handleRemoveVariation(variation)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton><h3>{variation}:</h3>
                                                    <IconButton
                                                        color="primary"
                                                        disabled={index === 0}
                                                        onClick={() => handleMoveVariation(index, index - 1)}
                                                    >
                                                        <ArrowUpIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        disabled={index === Object.keys(configurations).length - 1}
                                                        onClick={() => handleMoveVariation(index, index + 1)}
                                                    >
                                                        <ArrowDownIcon />
                                                    </IconButton>

                                                </div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Label</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {options.map((option, index) => (
                                                            <tr key={option.code}>
                                                                <td>
                                                                    <TextField
                                                                        type="text"
                                                                        value={option.code}
                                                                        size="small"
                                                                        onChange={(event) =>
                                                                            handleVariationChange(
                                                                                variation,
                                                                                index,
                                                                                'code',
                                                                                event.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        type="text"
                                                                        value={option.label}
                                                                        size="small"
                                                                        onChange={(event) =>
                                                                            handleVariationChange(
                                                                                variation,
                                                                                index,
                                                                                'label',
                                                                                event.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <IconButton

                                                                        onClick={() => handleRemoveOption(variation, index)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        color="primary"
                                                                        disabled={index === 0}
                                                                        onClick={() => handleMoveOption(variation, index, index - 1)}
                                                                    >
                                                                        <ArrowUpIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        color="primary"
                                                                        disabled={index === options.length - 1}
                                                                        onClick={() => handleMoveOption(variation, index, index + 1)}
                                                                    >
                                                                        <ArrowDownIcon />
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="2">

                                                                <div style={{ display: 'flex', justifyContent: 'center' }}><IconButton

                                                                    onClick={() => handleAddOption(variation)}
                                                                >
                                                                    <AddIcon />
                                                                </IconButton><p>Nova Opção</p></div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        )
                                    )}
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}><IconButton color="primary" onClick={handleOpenAddVariationDialog}>
                                            <AddIcon />
                                        </IconButton><p>Nova Variação</p></div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </>
                    )}
                </div>
            </div>

            <Dialog open={isConfirmationOpen} onClose={handleCancel}>
                <DialogTitle>
                    {confirmAction === "addVariation" ? "Nova Variação" : "Confirmar Ação"}
                </DialogTitle>
                <DialogContent>
                    {confirmAction === "addVariation" ? (
                        <TextField
                            label="Nome da Variação"
                            fullWidth
                            value={newVariationName}
                            onChange={(e) => setNewVariationName(e.target.value)}
                        />
                    ) : (
                        `Deseja mesmo ${confirmAction === "save" ? "salvar" : "descartar"} as alterações?`
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        {confirmAction === "addVariation" ? "Adicionar" : "Confirmar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default () => {
    const configurations = tankStructureConfigurationMap();
    return (<ConfigurationEditor configurations={configurations} />);

}
