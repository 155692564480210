import React from 'react';
import { Routes, Route, Link, useParams, Navigate } from 'react-router-dom';


import { AppBar, Toolbar, Typography, Button, Container, Card, CardContent, Grid, Box } from '@mui/material';
import { shadows } from '@mui/system';
import { useAuth } from './AuthContext';

import WrapperCard from './WrapperCard';
import Configurator from './Configurator';
import ConfiguratorEditor from './ConfiguratorEditor';
import ProductComponent from './ProductComponent';

const HomeTiles = ({ tiles }) => {
    return (
        <Container maxWidth="md" style={{ marginTop: '2rem' }}>
            <Grid container spacing={2}>
                {tiles.map((tile, index) => (
                    <Grid key={index} item xs={12} sm={4} md={4}>
                        <Link to={tile.link} style={{ textDecoration: 'none' }}>
                            <Card
                                sx={{
                                    boxShadow: 1,
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                    color: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                    p: 2,
                                    m: 2,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    fontWeight: '700',
                                }}
                            >
                                <Typography variant="h6">{tile.title}</Typography>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Container>

    )
}

const Home = () => {
    const { user, logout } = useAuth();
    const appName = "Configurador";

    const handleLogout = () => {
        logout();
    };

    const dashboardTiles = [
        { title: 'Configurador', link: '/home/configurator' },
        { title: 'Editar Configurador', link: '/home/edit-configurator' },
        { title: 'Test', link: '/home/product' },


        // Add more tiles as needed
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <AppBar position="static">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white' }}>
                            {appName}
                        </Typography>
                    </div>
                    <Typography>
                        Olá {user ? user.fullName : ''}
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </AppBar>
            <div style={{ flex: 1, overflowY: 'auto', justifyContent: 'center', alignContent: 'center', flexFlow: 'wrap' }}>
                <Routes>
                    <Route path="/" element={<HomeTiles tiles={dashboardTiles} />} />
                    <Route path="/configurator" element={<Configurator />} />
                    <Route path="/edit-configurator" element={<ConfiguratorEditor />} />

                </Routes>
            </div>
        </div>
    );
};

export default Home;
