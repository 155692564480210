import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import axios from './axiosConfig';
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
} from '@mui/material';

const ListOfComponents = ({ listOfComponents }) => {
  const [componentRows, setComponentRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchComponentRows() {
      setLoading(true);
      const rows = await getTableRows(listOfComponents);
      setComponentRows(rows);
      setLoading(false);
    }

    fetchComponentRows();
  }, [listOfComponents]);

  const fetchComponent = async (componentcode) => {
    try {
      const response = await axios.get(`/component/${componentcode}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getComponentFromDB = async (componentcode) => {
    try {
      const response = await fetchComponent(componentcode);
      if (response['count'] === 1) {
        return response['first'];
      } else {
        return 'Erro ao carregar descrição';
      }
    } catch (error) {
      console.error(error);
      return 'Erro ao carregar descrição';
    }
  };

  const getComponentRow = async (component) => {
    const dbComponent = await getComponentFromDB(component.codigo);
    debugger;
    return (
      <TableRow key={component.codigo}>
        <TableCell>{component.codigo}</TableCell>
        <TableCell>{dbComponent.descricao}</TableCell>
        <TableCell>{component.quantity}</TableCell>
        <TableCell>{dbComponent.um}</TableCell>
      </TableRow>
    );
  };

  const getTableRows = async (listOfComponents) => {
    const rows = await Promise.all(listOfComponents.map(getComponentRow));
    return rows;
  };

  const getLoadingIndicator = () => {
    return (
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>Componente</h2>
                </TableCell>
                <TableCell>
                  <h2>Descrição</h2>
                </TableCell>
                <TableCell>
                  <h2>Quantidade</h2>
                </TableCell>
                <TableCell>
                  <h2>Unidade</h2>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
        <h2>Carregando...</h2>        
      </div>
    )
  }

  const getDisplayTable = () => {
    return (
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>Componente</h2>
                </TableCell>
                <TableCell>
                  <h2>Descrição</h2>
                </TableCell>
                <TableCell>
                  <h2>Quantidade</h2>
                </TableCell>
                <TableCell>
                  <h2>Unidade</h2>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{componentRows}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <>
    {loading && (getLoadingIndicator())}
    {!loading && (getDisplayTable())}
    </>
  );
};

export default ListOfComponents;
