const getMountingComponents = (tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, componentName) => {
    const components = [];

    let fator_mp_secoes = parseFloat(tankStructureSelectedOptions["Seções"]);

    /*
    let fator_mp_tandem = 1;
    if (tankStructureSelectedOptions["Tandem"] === "Sim") {
        if (tankStructureSelectedOptions["Tipo de Bomba"] === "Hidráulico") {
            fator_mp_tandem = 2;
        }
        else {
            fator_mp_tandem = parseFloat(tankStructureSelectedOptions["Seções"]);
        }
    }
    */

    /*if (componentName === "Indutivo") {
        components.push({ codigo: "codI", quantity: 1 * fator_mp_secoes });
    }
    else if (componentName === "Semente") {
        components.push({ codigo: "codS", quantity: 1 * fator_mp_secoes });
    }
    else if (componentName === "Monitor de Plantio DJ") {
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            debugger;
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            if (controllerName.includes("ISOBUS")) {
                components.push({ codigo: "codMPDJISO", quantity: 1 * fator_mp_secoes });
            }
            else if (controllerName.includes("F-15")) {
                components.push({ codigo: "codMPDJF15", quantity: 1 * fator_mp_secoes });
            }
        }

    }
    else if (componentName === "Monitor de Plantio SPAU") {
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            debugger;
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            if (controllerName.includes("ISOBUS")) {
                components.push({ codigo: "codMPAUISO", quantity: 1 * fator_mp_secoes });
            }
            else if (controllerName.includes("F-15")) {
                components.push({ codigo: "codMPAUF15", quantity: 1 * fator_mp_secoes });
            }
        }
    }*/
    if (componentName === "Indutivo") {
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            components.push({ codigo: "73104045FT", quantity: 1 * fator_mp_secoes });
            components.push({ codigo: "FT3331EX", quantity: 1 * fator_mp_secoes });
            components.push({ codigo: "100841000042", quantity: 1 * fator_mp_secoes });
            if (controllerName === "F-15") {
                components.push({ codigo: "FT990101", quantity: 1 });
                //VOU ALTERAR A COMPOSIÇÃO/DESCRIÇÃO DO FT990101 PARA APENAS OS ITENS DO KIT ELETRONICO (SEM ACIONAMENTO)
            }
            else if (controllerName === 'ISOBUS MA') {
                components.push({ codigo: "FT990104", quantity: 1 });
            }
            else if (controllerName === "ISOBUS FORTRON") {
                components.push({ codigo: "FT990105", quantity: 1 });

            }
        }
    }
    else if (componentName === "Semente") {
        components.push({ codigo: "FT4109-S2", quantity: 1 * fator_mp_secoes });
        components.push({ codigo: "FT3331EX", quantity: 1 * fator_mp_secoes });

        debugger;
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            if (controllerName === 'ISOBUS FORTRON') {
                components.push({ codigo: "FT990102", quantity: 1 });
            }
            else if (controllerName === 'ISOBUS MA') {
                components.push({ codigo: "FT990103", quantity: 1 });
            }
            else if (controllerName === "F-15") {
                components.push({ codigo: "FT990101", quantity: 1 });
                //XX = QUANTIDADE DE LINHAS
            }
        }
    }
    else if (componentName === "Monitor de Plantio DJ") {
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            debugger;
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            if (controllerName.includes("ISOBUS")) {
                components.push({ codigo: "FT99114-XX", quantity: 1 * fator_mp_secoes });
                //XX = QUANTIDADE DE LINHAS

            }
            else if (controllerName.includes("F-15")) {
                components.push({ codigo: "FT2233-XX", quantity: 1 * fator_mp_secoes });
                //XX = QUANTIDADE DE LINHAS
            }
        }

    }
    else if (componentName === "Monitor de Plantio SPAU") {
        if (electricalInterfaceSelectedOptions && electricalInterfaceSelectedOptions["Controle"]) {
            debugger;
            const controllerName = electricalInterfaceSelectedOptions["Controle"];
            if (controllerName.includes("ISOBUS")) {
                components.push({ codigo: "FT99113-A", quantity: 1 * fator_mp_secoes }); //QUANTIDADE MULTIPLICADA PELO N° DE LINHAS
                //SE MAIS REG
                //components.push({ codigo: "FT99113-S0", quantity: 1 * fator_mp_secoes })

            }
            else if (controllerName.includes("F-15")) {
                components.push({ codigo: "FT8289", quantity: 1 * fator_mp_secoes });
                //QUANTIDADE MULTIPLICADA PLEO N° DE LINHAS


            }
        }
    }


    return components
}

const electricalInterfaceConfigurationMap = (tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions) => {
    let configurations = {
        "Sensor": [
            {
                label: "Indutivo", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "Indutivo")
            },
            {
                label: "Semente", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "Semente")
            },
            {
                label: "Monitor de Plantio DJ", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "Monitor de Plantio DJ")
            },
            {
                label: "Monitor de Plantio SPAU", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "Monitor de Plantio SPAU")
            }
        ],
        "Controle": [
            { label: "F-15", code: "SP", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "F-15") },
            { label: "ISOBUS FORTRON", code: "ML", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "ISOBUS FORTRON") },
            { label: "ISOBUS MA", code: "MT", components: getMountingComponents(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricalInterfaceSelectedOptions, "ISOBUS MA") },
        ]

    };

    return configurations;
}

export default electricalInterfaceConfigurationMap;