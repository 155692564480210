import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
    Typography,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ProductComponent from './ProductComponent';

import { useAuth } from './AuthContext';
import { get, set } from 'react-hook-form';
import tankStructureConfigurationMap from './TankStructureConfigurationMap';
import tankInterfaceConfigurationMap from './TankInterfaceConfigurationMap';
import electricalInterfaceConfigurationMap from './ElectricalInterfaceConfigurationMap';
import ListOfComponents from './ListOfComponents';


const validateForm = (configurations, selectedOptions) => {
    let valid = Object.keys(selectedOptions).every((variation) => !!selectedOptions[variation]);
    let msg = '';
    if (selectedOptions['Tipo de Bomba'] === 'Elétrico') {
        if (selectedOptions['Reservatório'] === '1200' || selectedOptions['Reservatório'] === '2200') {
            valid = false;
            msg += 'Reservatório deve ser 400, 600 ou 800 para Elétrico\n';
        }
        if (selectedOptions['Tandem'] === 'Sim' && selectedOptions['Seções'] === '1') {
            valid = false;
            msg += 'Tandem precisa de mais do que 1 seção quando elétrico.\n';
        }
        if (selectedOptions['Tandem'] === 'Não' && selectedOptions['Seções'] !== '1') {
            valid = false;
            msg += 'Elétrico de várias seções necessita Tandem\n';
        }
        if (selectedOptions['Taxa'] === 'Sim' && selectedOptions['Seções'] !== '1') {
            valid = false;
            msg += 'Taxa precisa de 1 seção quando Elétrico.\n';
        }
        /*if (selectedOptions['Taxa'] === 'Sim' && selectedOptions['Controle'] !== 'ISOBUS') {
            valid = false;
            msg += 'Taxa precisa de ISOBUS quando Elétrico.\n';
        }*/
    }
    if (selectedOptions['Abastecimento'] === 'Engate Rápido' && selectedOptions['Reservatório'] === '400') {
        valid = false;
        msg += 'Reservatório de 400 não aceita Engate Rápido\n';
    }

    if (valid) {
        Object.keys(configurations).map((variation) => {
            if (selectedOptions[variation] === undefined) {
                valid = false;
                msg += 'Selecione uma opção para ' + variation + '\n';
            }
            return true;
        });
    }

    let msgs = msg.split('\n');
    let divs = msgs.map((m) => <div key={m}>{m}</div>);
    msg = <div>{divs}</div>;

    return { valid: valid, msg: msg };
};

const EquipmentConfigurator = ({ configurations, selectedOptions, onSelectionChange }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [validation, setValidation] = useState({ valid: false, msg: 'Nada selecionado' });

    const handleOptionChange = (variation, option) => {
        const new_options = {
            ...selectedOptions,
            [variation]: option,
        };
        onSelectionChange(new_options);
        const local_validation = validateForm(configurations, new_options);
        setValidation(local_validation);
    };

    useEffect(() => {
        if (!validation.valid) {
            setErrorMsg(validation.msg);
        }
        else {
            setErrorMsg(null);
        }
    }, [validation]);

    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.entries(configurations).map(
                    ([variation, options]) => (

                        <div key={variation} style={{ flex: '1 0 25%', marginBottom: '1rem' }}>
                            <h3>{variation}:</h3>
                            <div style={{ maxWidth: '90%' }}>
                                {options.length > 5 ? (

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>{variation}</InputLabel>
                                        <Select
                                            size="small"
                                            value={selectedOptions[variation] || ''}
                                            onChange={(event) =>
                                                handleOptionChange(variation, event.target.value)
                                            }
                                            label={variation}
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option.label} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                ) : (
                                    <RadioGroup
                                        aria-label={variation}
                                        name={variation}
                                        value={selectedOptions[variation] || ''}
                                        onChange={(event) =>
                                            handleOptionChange(variation, event.target.value)
                                        }
                                    >
                                        {options.map((option) => (
                                            <FormControlLabel
                                                key={option.label}
                                                value={option.label}
                                                control={<Radio size="small" />}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                            </div>
                        </div>
                    )
                )}
            </div>
            <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
                <div >{errorMsg}</div>
            </div>


        </>
    );
};


const Configurator = () => {
    let tankConfigurations = tankStructureConfigurationMap();
    const tankStructureName = 'Estrutura Tanque';
    const tankInterfaceName = 'Instalação Mecânica Tanque';
    const { user, isAuthenticated, logout } = useAuth();
    //create a local state to store the generated code
    const [structureCode, setStructureCode] = useState(null);
    const [tankStructureRawCode, setTankStructureRawCode] = useState(null);

    const [showComponentsStructure, setShowComponents] = useState(false);
    const [validCode, setValidCode] = useState(false);
    const [tankStructureSelectedOptions, setTankStructureSelectedOptions] = useState({});
    const [tankInterfaceSelectedOptions, setTankInterfaceSelectedOptions] = useState({});
    const [tankInterfaceSelectedComponents, setTankInterfaceSelectedComponents] = useState([]);

    const [electricaInterfaceSelectedOptions, setElectricalInterfaceSelectedOptions] = useState({});
    const [electricalInterfaceSelectedComponents, setElectricalInterfaceSelectedComponents] = useState([]);


    const handleTankCodeChanged = (valid, code) => {
        code = "FT" + code;
        setValidCode(valid);
        console.log('raw code: ' + code);
        setTankStructureRawCode(code);

        if (code && code.includes('X')) {
            setStructureCode(null);
            return;
        }
        // structure code is the first 10 characters of the generated code
        if (code) {
            setStructureCode(code.substring(0, 10));
        }
        else {
            setStructureCode(null);
        }
        console.log(structureCode);
    }

    const handleShowComponents = () => {
        if (showComponentsStructure) {
            setShowComponents(false);
        }
        else {
            setShowComponents(true);
        }
    }
    const getStuff = () => {
        debugger;
        console.log(tankConfigurations);
        return (
            <div>
            </div>
        )
    }

    const tankCodeCreator = (configurations, selectedOptions) => {
        setTankStructureSelectedOptions(selectedOptions);
        let code = '';
        Object.keys(configurations).map((variation) => {
            const possibleOptions = configurations[variation];
            const selectedOpt = selectedOptions[variation];
            const selectedOptCode = possibleOptions.find((opt) => opt.label === selectedOpt)?.code;
            const minCodeLen = possibleOptions[0].code.length;
            if (selectedOptCode) {
                code += selectedOptCode;
            } else {
                for (let i = 0; i < minCodeLen; i++)
                    code += 'X';
            }
        });
        console.log(code);
        return code;
    }

    const getTankInterfaceConfigurations = (tankStructureSelectedOptions) => {
        const tankInterfaceConfigurations = tankInterfaceConfigurationMap(tankStructureSelectedOptions);
        return tankInterfaceConfigurations;
    }

    const getElectricalInterfaceConfigurations = (tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricaInterfaceSelectedOptions) => {
        const electricalInterfaceConfigurations = electricalInterfaceConfigurationMap(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricaInterfaceSelectedOptions);
        return electricalInterfaceConfigurations;
    }

    const handleTankConfigurationChange = (selection) => {
        setTankStructureSelectedOptions(selection);

        const code = tankCodeCreator(tankConfigurations, selection);
        handleTankCodeChanged(true, code);
    }

    const handleTankInterfaceConfigurationChange = (selection) => {
        setTankInterfaceSelectedOptions(selection);
    }

    const handleElectricalInterfaceConfigurationChange = (selection) => {
        setElectricalInterfaceSelectedOptions(selection);
    }

    const getTankInterfaceConfigurationsOptions = () => {
        return (
            <EquipmentConfigurator
                configurations={getTankInterfaceConfigurations(tankStructureSelectedOptions)}
                selectedOptions={tankInterfaceSelectedOptions}
                onSelectionChange={handleTankInterfaceConfigurationChange}
            >

            </EquipmentConfigurator>
        )
    };

    const getElectricalInterfaceConfigurationOptions = (electricalInterfaceConfigurations) => {
        return (
            <EquipmentConfigurator
                configurations={electricalInterfaceConfigurations}
                selectedOptions={electricaInterfaceSelectedOptions}
                onSelectionChange={handleElectricalInterfaceConfigurationChange}
            >

            </EquipmentConfigurator>
        )
    }

    useEffect(() => {
        const tankConfigurations = getTankInterfaceConfigurations(tankStructureSelectedOptions);
        let components_from_selection = [];
        Object.keys(tankInterfaceSelectedOptions).map((variation) => {
            const possibleOptions = tankConfigurations[variation];
            const selectedOpt = tankInterfaceSelectedOptions[variation];
            if (possibleOptions && selectedOpt) {
                const selectedOptComponents = possibleOptions.find((opt) => opt.label === selectedOpt)?.components;
                if (selectedOptComponents) {
                    components_from_selection = [...components_from_selection, ...selectedOptComponents];
                }
            }
        });
        setTankInterfaceSelectedComponents(components_from_selection);
    }, [tankStructureSelectedOptions, tankInterfaceSelectedOptions]);


    useEffect(() => {
        const electricalInterfaceConfigurations = getElectricalInterfaceConfigurations(tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricaInterfaceSelectedOptions);
        let components_from_selection = [];
        debugger;
        Object.keys(electricaInterfaceSelectedOptions).map((variation) => {
            const possibleOptions = electricalInterfaceConfigurations[variation];
            const selectedOpt = electricaInterfaceSelectedOptions[variation];
            if (possibleOptions && selectedOpt) {
                const selectedOptComponents = possibleOptions.find((opt) => opt.label === selectedOpt)?.components;
                if (selectedOptComponents) {
                    components_from_selection = [...components_from_selection, ...selectedOptComponents];
                }
            }
        });
        setElectricalInterfaceSelectedComponents(components_from_selection);
    }, [tankStructureSelectedOptions, tankInterfaceSelectedOptions, electricaInterfaceSelectedOptions]);



    const getElectricalInterfaceSelectedComponentsTable = (electricalInterfaceSelectedComponents) => {
        return (
            <>
                <h3>compas Inst Elétrica</h3>
                <ListOfComponents listOfComponents={electricalInterfaceSelectedComponents} />
            </>
        )
    }

    return (
        <div style={{ flexDirection: 'column', paddingTop: '1em' }}>
            <Typography variant="h5" component="div">
                Código Estrutura: {tankStructureRawCode}
            </Typography>
            <EquipmentConfigurator
                configurations={tankConfigurations}
                selectedOptions={tankStructureSelectedOptions}
                onSelectionChange={handleTankConfigurationChange}
            />
            {structureCode && !showComponentsStructure && validCode ? <Button onClick={handleShowComponents}>Mostrar componentes</Button> : <></>}
            {structureCode && showComponentsStructure && validCode ? <Button onClick={handleShowComponents}>Ocultar componentes</Button> : <></>}
            {structureCode && showComponentsStructure ? <div><ProductComponent productcode={structureCode} /></div> : <></>}

            {structureCode ? getTankInterfaceConfigurationsOptions(getTankInterfaceConfigurations(tankStructureSelectedOptions)) : <></>}



            {
                structureCode ? <ListOfComponents listOfComponents={tankInterfaceSelectedComponents} />
                    : <></>
            }
            {structureCode ? getElectricalInterfaceConfigurationOptions(getElectricalInterfaceConfigurations(tankStructureSelectedOptions, tankInterfaceSelectedOptions)) : <></>}
            {
                structureCode ? <ListOfComponents listOfComponents={electricalInterfaceSelectedComponents} />
                    : <></>
            }



            {/*getStuff()*/}


        </div>
    )
}


export default Configurator;
