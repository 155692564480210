import React, { useState } from 'react';
import { Container, Typography, TextField, Button, ThemeProvider } from '@mui/material';
import axios from 'axios';

import theme from './theme'; // Import your custom theme

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');

    const handlePasswordRecovery = async () => {
        try {
            const response = await axios.post('/reset_password_request', { email });
            if (response.status === 200) {
                // Password reset email sent, handle success message
            } else {
                // Handle error message
            }
        } catch (error) {
            // Handle error
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Password Recovery
                </Typography>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <Button onClick={handlePasswordRecovery} variant="contained" color="primary">
                    Request Password Reset
                </Button>
            </Container>
        </ThemeProvider>
    );
};

export default PasswordRecovery;
