import React, { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useAuth } from './AuthContext';
import WrapperCard from './WrapperCard';
import Login from './Login';
import Signup from './Signup';
import PasswordRecovery from './PasswordRecovery';
import Home from './Home';
import LoadingAnimation from './LoadingAnimation';

function App() {
    const { user, isAuthenticated, logout } = useAuth();
    useEffect(() => {
        document.title = 'Configurador';
    
        // This function will be called when the component unmounts
        return () => {
          document.title = 'Configurador';
        };
      }, []);
    return (

        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated() ? (user ? <Navigate to="/home" /> : <LoadingAnimation />) : <Navigate to="/login" />} />
                <Route path="/login" element={isAuthenticated() ? <Navigate to="/" /> : <Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/reset-password" element={<PasswordRecovery />} />
                <Route path="/home/*" element={isAuthenticated() ? <Home handleLogout={logout} user={user} /> : <Navigate to="/login" />} />
            </Routes>

        </Router>

    );
}

export default App;
