import React, { useState } from 'react';
import { Container, Card, CardContent, Typography, TextField, Button, ThemeProvider } from '@mui/material';
import axios from './axiosConfig'; // Import the configured Axios instance
import { useForm, Controller, Resolver } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import theme from './theme'; // Import your custom theme

//import PhoneInput from 'react-phone-number-input';
//import 'react-phone-number-input/style.css';
import { MuiTelInput } from 'mui-tel-input'




const Signup = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);

    const handleSignup = async (data) => {
        try {
            const response = await axios.post('/signup', data)
            // the backend will return 409 status if the user already exists and 201 if signup is successful
            // on success, redirect the user to the home page
            // on error, display the error message
            if (response.status === 201) {
                navigate('/');
            } else {
                // display error message
            }

        } catch (error) {
            // Handle error
            setErrorMessage('Erro ao criar conta');
        }
    };

    const { control, handleSubmit, formState: { errors, isValid }, getValues } = useForm({ mode: 'all' });
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card sx={{
                    boxShadow: 4,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    p: 2,
                    m: 2,
                    borderRadius: 2,
                    textAlign: 'center',
                    fontWeight: '700',
                }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Criar Conta
                        </Typography>
                        <form onSubmit={handleSubmit(handleSignup)}>
                            <Controller
                                name="fullName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label="Nome Completo"
                                        {...field}
                                        fullWidth
                                        error={errors.fullName && true}
                                        helperText={errors.fullName ? errors.fullName.message : ''}
                                    />
                                )}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                errors.fullName = { message: 'Nome Completo é obrigatório' };
                                                return false;
                                            };
                                        }
                                    },
                                    maxLength: 100,
                                    minLength: 5,
                                }}
                            />
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label="Email"
                                        {...field}
                                        fullWidth
                                        error={errors.email && true}
                                        helperText={errors.email ? errors.email.message : ''}
                                    />
                                )}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                errors.email = { message: 'Email é obrigatório' };
                                                return false;
                                            }
                                            else {
                                                if (!value.includes("@")) {
                                                    errors.email = { message: 'Email é obrigatório' };
                                                    return false;
                                                }
                                            }
                                        }
                                    },
                                    maxLength: 100,
                                    minLength: 5,
                                }}
                            />

                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field }) => (
                                    <MuiTelInput
                                        {...field}
                                        fullWidth
                                        placeholder="Telefone"
                                        defaultCountry="BR" // You can set your desired default country
                                        error={errors.phoneNumber && true}
                                        helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                                    />
                                )}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                errors.phoneNumber = { message: 'Telefone é obrigatório' };
                                                return false;
                                            }
                                        }
                                    },
                                    maxLength: 100,
                                    minLength: 5,
                                }}

                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label="Senha"
                                        type="password"
                                        {...field}
                                        fullWidth
                                        error={errors.password && true}
                                        helperText={errors.password ? errors.password.message : ''}
                                    />
                                )}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                errors.password = { message: 'Senha é obrigatório' };
                                                return false;
                                            }
                                        }
                                    },
                                    maxLength: 100,
                                    minLength: 5,
                                }}
                            />
                            <Controller
                                name="passwordConfirmation"
                                control={control}
                                render={({
                                    field
                                }) => (
                                    <TextField
                                        label="Confirmar Senha"
                                        type="password"
                                        {...field}
                                        fullWidth
                                        error={errors.passwordConfirmation && true}
                                        helperText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
                                    />
                                )}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                errors.passwordConfirmation = { message: 'Confirmar Senha é obrigatório' };
                                                return false;
                                            }
                                        },
                                        matchesPreviousPassword: (value) => {
                                            const { password } = getValues();
                                            if (password !== value) {
                                                errors.passwordConfirmation = { message: 'Senhas estão diferentes' };
                                                return false;
                                            }
                                        }
                                    },
                                    maxLength: 100,
                                    minLength: 5,
                                }}
                            />

                            <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                                Criar Conta
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Container>
            {errorMessage && (
                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Typography color="error">{errorMessage}</Typography>
                </div>
            )}
        </ThemeProvider>
    );
};

export default Signup;