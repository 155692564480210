import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from './axiosConfig'; // Import the configured Axios instance

const AuthContext = createContext();



export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check for token on page load
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setAuthenticated(true);
            // You might want to fetch user data using the token here
            const resp = fetchUserDataUsingToken(authToken).then((resp) => {
                setUser(resp['user']);
                if (resp['token'])
                    localStorage.setItem('authToken', resp['token']);
            });
        }
    }, []);

    const login = (user, authToken) => {
        setUser(user);
        setAuthenticated(true);
        // Store the authentication token
        localStorage.setItem('authToken', authToken);
    };

    const logout = () => {
        setUser(null);
        setAuthenticated(false);
        // Remove the authentication token
        localStorage.removeItem('authToken');
    };

    const isAuthenticated = () => {
        return authenticated;
    };

    const fetchUserDataUsingToken = async (token) => {
        try {
            const response = await axios.get('/user',
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
            if (response.status === 200) {
                return { user: response.data['user'], token: token };
            } else {
                debugger;
            }
        } catch (error) {
            logout();
        }
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
