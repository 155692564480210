import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import axios from './axiosConfig'; // Import the configured Axios instance
import {
    Button,
    Card,
    CardContent,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

const ProductComponent = ({ productcode }) => {
    const [product, setProduct] = useState(null);
    const [editing, setEditing] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const { user, isAuthenticated, logout } = useAuth();
    const userLevel = user['userLevel']

    const fetchProduct = async (productcode) => {
        try {
            const response = await axios.get(`/product/${productcode}`);
            setProduct(response.data);
        } catch (error) {
            setProduct(null);
            console.error(error);
        }
    };

    const fetchComponent = async (componentcode) => {
        try {
            const response = await axios.get(`/component/${componentcode}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const setNewComponentCode = (value, index) => {
        const newProduct = { ...product };
        newProduct.components[index].component_code = value;
        setProduct(newProduct);
    };

    const setNewComponentQuantity = (value, index) => {
        const newProduct = { ...product };
        newProduct.components[index].quantity = value;
        setProduct(newProduct);
    };

    const handleComponentRemoveClick = (index) => {
        const newProduct = { ...product };
        newProduct.components.splice(index, 1);
        setProduct(newProduct);
    };

    const handleComponentAddClick = () => {
        const newProduct = { ...product };
        newProduct.components.push({ component_code: '', quantity: '' });
        setProduct(newProduct);
    };

    const getComponentsEditRows = () => {
        return product.components.map((component, index) => (
            <TableRow>
                <TableCell>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={component.component_code}
                        onChange={(e) => {
                            setNewComponentCode(e.target.value, index)
                            fetchComponent(e.target.value)
                                .then((response) => {
                                    const newProduct = { ...product };
                                    if (response['count'] === 1) {
                                        
                                        newProduct.components[index].description = response['first']['descricao'];
                                        
                                    }
                                    else
                                    {
                                        newProduct.components[index].description = '';
                                    }
                                    setProduct(newProduct);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                        }

                    />
                </TableCell>
                <TableCell>
                    {component.description}
                </TableCell>
                <TableCell>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={component.quantity}
                        onChange={(e) => setNewComponentQuantity(e.target.value, index)}
                    />
                </TableCell>
                <TableCell>
                    <IconButton color="primary" onClick={() => handleComponentRemoveClick(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        ))

    }

    useEffect(() => {
        fetchProduct(productcode);
    }, [productcode]);

    const testComponentsList = (product) => {
        if (product && product.components && product.components.length > 0) {
            for (let idx in product.components) {
                let component = product.components[idx];
                if (component.component_code === '') {
                    return false;
                }
                if (component.quantity === '') {
                    return false;
                }
                else if (isNaN(component.quantity)) {
                    toast.error('Quantidade deve ser um número');
                    return false;
                }
            }
            return true;
        }
        return false;
    }


    useEffect(() => {
        setCanSave(testComponentsList(product));
    }, [product]);


    const handleEditClick = () => {
        setEditing(true);
    };

    const handleSaveClick = () => {
        if (!testComponentsList(product)) {
            toast.error('Confira lista de componentes');
            return;
        }
        axios.put(`/product/${productcode}`, product)
            .then((response) => {
                setEditing(false);
                fetchProduct(productcode);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCancelClick = () => {
        setEditing(false);
        fetchProduct(productcode);
    };

    const handleAddClick = () => {
        // Perform the add logic here
        // Make the necessary API request to add the component
        setEditing(true);
        setProduct({ code: productcode, components: [{ component_code: '', quantity: '' }] });
    };

    return (
        <div>
            {product && editing ? (
                <div>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><h2>Componente</h2></TableCell>
                                    <TableCell><h2>Descrição</h2></TableCell>
                                    <TableCell><h2>Quantidade</h2></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    product.components ? getComponentsEditRows() : null
                                }
                                <TableRow>
                                    <TableCell>

                                        <IconButton color="primary" onClick={() => handleComponentAddClick()}>
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {canSave ? <Button onClick={handleSaveClick}>Salvar</Button> : <Button disabled>Salvar</Button>}
                    <Button onClick={handleCancelClick}>Cancelar</Button>
                </div>
            ) : (
                <div>
                    {product ? (
                        <div>
                            <Typography variant="h6" component="div">
                                Componentes:
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><h2>Componente</h2></TableCell>
                                            <TableCell><h2>Descrição</h2></TableCell>
                                            <TableCell><h2>Quantidade</h2></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {product.components.map((component) => (
                                            <TableRow key={component.component_code}>
                                                <TableCell>{component.component_code}</TableCell>
                                                <TableCell>{component.description}</TableCell>
                                                <TableCell>{component.quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {userLevel === 'admin' ? (
                                <Button onClick={handleEditClick}>Editar</Button>
                            ):<></>}
                        </div>
                    ) : (
                        <Typography variant="h5" component="div">
                            <div style={{ margin: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><Button variant="contained" color="success" onClick={handleAddClick}>Adicionar Produto</Button></div>
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProductComponent;